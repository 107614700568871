import qs from 'qs';

import { type Product } from '~/services/main';

import { type ApiResponse, type ApiResponseInner } from '../../types/global';
import BaseService from '../BaseService';
import { type SeoData } from './type';

class SeoService extends BaseService {
  constructor() {
    super('');
  }

  fetchProductDetailSeo(displaySeq: number) {
    const { apiBase } = useRuntimeConfig().public;
    return $fetch<ApiResponse<ApiResponseInner<SeoData>>>(`${apiBase}/v1/user/class/seo/${displaySeq}`, {});
  }

  // 추후에 상페에서 SEO 사용시 대비하여 주석처리하여 남겨두었습니다.
  fetchProductDetail(displaySeq: number) {
    const { apiBase } = useRuntimeConfig().public;
    return $fetch<ApiResponse<ApiResponseInner<Product>>>(`${apiBase}/v1/user/class/detail/${displaySeq}`, {});
  }

  fetchCommunitySeo(boardContentsSeq: string) {
    const { apiBase } = useRuntimeConfig().public;
    return $fetch<ApiResponse<ApiResponseInner<SeoData>>>(
      `${apiBase}/v1/user/community/board/contents/${boardContentsSeq}/seo`,
      {},
    );
  }

  fetchMainSeo() {
    const { apiBase } = useRuntimeConfig().public;
    return $fetch<ApiResponse<ApiResponseInner<SeoData>>>(`${apiBase}/v1/user/main/seo`, {});
  }

  fetchProductReviews(params) {
    const { apiBase } = useRuntimeConfig().public;
    const queryString = qs.stringify(params);
    return $fetch(`${apiBase}/v1/search/reviews?${queryString}`);
  }
}

export const seoService = new SeoService();
